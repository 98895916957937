/*header .top-menu .search-box input{
	width: 74%;
}*/

.menu-btns img{
	position: relative;
	top: 0.5em;
}

.search-box .search-submit{
	border-color: #9dcb92;
}

h3{
	font-weight: normal;
}

.logo img{
	border: none;
}

.grid li.block-dark.block article footer > a{
    margin-right:1px;
    padding:5px;
}

.gerelateerd-side > .tel{
    font-size:smaller;
}

body div#cookiewarning div#back{
	padding-top: 0;
}

body div#cookiewarning div#back div{
	height: 2em;
	padding-top: 0.3em;
}
